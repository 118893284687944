<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab :to="{ name: 'login' }" class="no-caps" :ripple="false">
            {{ $t('log_in') }}
            <!-- <v-icon size="24">mdi-account</v-icon> -->
          </v-tab>
          <v-tab :to="{ name: 'password.email' }" class="no-caps" :ripple="false">
            {{ $t('forgot_password') }}
            <!-- <v-icon size="24">mdi-key-variant</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: transparent; overflow: visible;">
          <v-tab-item value="tab-0">
            <div class="mb-8 mt-8">
              <v-row>
                <v-col
                  v-for="(item, index) in all_account" :key="index"
                  class="d-flex child-flex"
                  cols="12"
                  sm="6"
                >
                  <div class="item-img-account">
                    <v-btn
                      @click="RemoveAccount(item.uuid)"
                      x-small
                      color="red--text"
                      depressed
                      fab
                      class="delete-remember-account"
                      >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                      @click="SwitchAccount(item,index)"
                      depressed
                      height="auto"
                      rounded
                      block
                      :loading="loading_switch && index==index_switch"
                    >
                      <v-list-item two-line class="text-left px-0">
                        <v-list-item-avatar
                          size="40"
                          color="grey"
                        >
                          <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle1">
                            {{ item.first_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="caption">
                            {{ item.email }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-btn>
                    <!-- <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5 mb-1">
                          {{ item.first_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.email }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="80"
                        color="grey"
                      >
                      </v-list-item-avatar>
                    </v-list-item> -->

                    <!-- <v-card-actions class="space-between">
                      <v-btn
                      @click="SwitchAccount(item,index)"
                      small
                      color="green white--text"
                      depressed
                      :loading="loading_switch && index==index_switch"
                      >
                        {{ $t('login') }}<v-icon right>mdi-login</v-icon>
                      </v-btn>
                      <v-btn
                      @click="RemoveAccount(item.uuid)"
                      small
                      color="red white--text"
                      depressed
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-actions> -->
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-card class="content-tabs-card px-8 py-12 rounded">
              <ValidationObserver ref="form1" v-slot="{ invalid }">
                <v-form 
                  :model="form1" 
                  @submit.prevent="submit('form1')"
                  autocomplete="off"
                  method="post"
                >
                  <h1 class="text-display-2 font-weight-medium mb-4">{{ $t('sign_in') }}</h1>
                  <p class="mb-8 text-subtitle-1 text--secondary">{{ $t('sign_in_head') }} <router-link :to="{name: 'register'}">{{ $t('sign_up') }}</router-link></p>
                  <v-alert
                    :value="form1.hasError"
                    type="error"
                    text
                    class="mb-4"
                  >
                    <span v-html="$t('login_not_recognized')"/>
                  </v-alert>
                  <v-alert
                    :value="successRegistrationRedirect"
                    type="success"
                    text
                    class="mb-4"
                  >
                    {{ $t('successfully_registered_info') }}
                  </v-alert>
                  <v-alert
                    :value="successResetUpdateRedirect"
                    type="success"
                    text
                    class="mb-4"
                  >
                    {{ $t('password_reset_success') }}
                  </v-alert>
                  <div v-if="$init.config.demo">
                    <div class="mb-3 font-weight-bold">
                      {{ $t('login_demo_mode') }}
                    </div>
                    <div class="mb-8">
                      <v-btn color="ctaBg ctaFg--text" width="105" small depressed tile class="mr-2 no-caps" @click="form1.email='admin@example.com';form1.password='welcome123';submit('form1')">{{ $t('admin') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn>
                      <v-btn color="ctaBg ctaFg--text" width="105" small depressed tile class="mr-2 no-caps" @click="form1.email='customer@example.com';form1.password='welcome123';submit('form1')">{{ $t('customer') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn>
                      <v-btn color="ctaBg ctaFg--text" width="105" small depressed tile class="no-caps" @click="form1.email='business@example.com';form1.password='welcome123';submit('form1')">{{ $t('business') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn>
                    </div>
                  </div>
                  <x-text-field 
                    type="email"
                    v-model="form1.email"
                    ref="form1.email"
                    id="form1.email"
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    rules="required|email"
                  />
                  <x-password
                    v-model="form1.password"
                    ref="form1.password"
                    id="form1.password"
                    :label="$t('password')"
                    :placeholder="$t('password')"
                    rules="required|min:8|max:24"
                  />
                  <x-checkbox
                    v-model="form1.rememberMe"
                    ref="form1.rememberMe"
                    id="form1.rememberMe"
                    :label="$t('remember_me')"
                  />
                  <v-btn color="primary" class="no-caps mt-4" rounded x-large depressed block :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('log_in') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-form>
              </ValidationObserver>

              <v-btn 
                color="primary" 
                class="no-caps mt-4" 
                rounded x-large depressed block 
                @click="handleClickSignIn"
                :disabled="!isInit || loading_google"
                :loading="loading_google"
              >
              <v-icon left>mdi-google</v-icon>
               {{ $t('log_in_with_google') }} 
              </v-btn>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: 'tab-0',
    topBgImg: 'about:blank',
    topBgImgHeight: null,
    topBgImgDefaultHeight: 780,
    topBgImgAspectRation: 1.7778,
    topBgImgs: [],
    successRegistrationRedirect: false,
    successResetUpdateRedirect: false,
    all_account: [],
    loading_switch: false,
    index_switch: -1,
    form1: {
      loading: false,
      email: '',
      password: '',
      rememberMe: true,
      hasError: false,
    },
    isInit: false,
    isSignIn: false,
    loading_google: false
  }),
  created () {
    this.successRegistrationRedirect = this.$route.params.successRegistrationRedirect || false
    this.form1.email = this.$route.params.email || null
    this.successResetUpdateRedirect = this.$route.params.successResetUpdateRedirect || false

    this.AccountSession()

    let that = this;
    let checkGauthLoad = setInterval(function () {
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      //console.log(that.$gAuth.isInit)
      //console.log(that.$gAuth.isAuthorized)
      if (that.isInit) clearInterval(checkGauthLoad);
    }, 1000);
  },
  methods: {
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        //console.log(googleUser.getBasicProfile())
        //console.log(googleUser.getAuthResponse())
        let id_token = googleUser.getAuthResponse().id_token
        //console.log(id_token)
        /*
        console.log("googleUser", googleUser);
        console.log("getId", googleUser.getId());
        console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("getAuthResponse", googleUser.getAuthResponse());
        console.log(
          "getAuthResponse",
          this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
        );
        */
        this.isSignIn = this.$gAuth.isAuthorized;
        /*
        this.loading_google = true
        this.axios.post('/auth/log-in-google', {
          id_token: id_token,
        })
        .then(res => {
          if (res.data.status === 'success') {
            console.log(res.data)
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(this.$t('not_found'))
          }
        })
        .finally(() => this.loading_google = false)
        */
        // this.loading_google = true
        // this.$auth.impersonate({
        //     params: {
        //       id_token: id_token,
        //       type: 'google'
        //     },
        //     success: function (res) {
        //       this.loading_google = false
        //       if (res.data.status === 'success') {
        //         console.log(res.data)
        //       }
        //     },
        //     error: function (err) {
        //       this.loading_google = false
        //       if (err.response.data.status === 'error') {
        //         this.$root.$snackbar(this.$t('not_found'))
        //       }
        //     },
        //     redirect: {name: 'home'},
        // })
        this.loading_google = true
        let redirect = this.$auth.redirect()
        this.$auth.login({
          rememberMe: true,
          fetchUser: true,
          params: {
            locale: this.$i18n.locale,
            remember: true,
            id_token: id_token,
            type: 'google'
          },
          success () {
            this.loading_google = false
            let redirectTo

            if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.dashboard'
            if (parseInt(this.$auth.user().role) == 2) redirectTo = 'customer.earn'
            if (parseInt(this.$auth.user().role) == 3) redirectTo = 'business.dashboard'
            if (parseInt(this.$auth.user().role) == 4) redirectTo = 'business.dashboard'

            // Redirect
            this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
            this.$root.$refs.master.getCredit()
          },
          error: function (error) {
            this.loading_google = false
            this.$root.$snackbar(this.$t('not_found'))
          }
        })
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    SwitchAccount (item,index) {
      //let token = item.token
      let email = item.email
      let password = item.password
      //let role = item.role
      if(password){
        /*
        //console.log(token)
        //let redirect = this.$auth.redirect()
        let that = this
        this.axios.post('/auth/set_authorization_token', {
          //locale: this.$i18n.locale,
          token: token,
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)

            let redirectTo = '/'
            if(role){
              if (parseInt(role) == 1) redirectTo = '/admin/dashboard'
              if (parseInt(role) == 2) redirectTo = '/customer/earn'
              if (parseInt(role) == 3) redirectTo = '/business/issue-points'
              if (parseInt(role) == 4) redirectTo = '/business/issue-points'
            }
            window.location.href = redirectTo;
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
            this.form1.email = email
          }
        })
        */
        this.loading_switch = true
        this.index_switch = index
        
        let redirect = this.$auth.redirect()
        this.$auth.login({
          rememberMe: true,
          fetchUser: true,
          params: {
            locale: this.$i18n.locale,
            email: email,
            password: password,
            remember: true
          },
          success () {
            this.loading_switch = false
            this.index_switch = -1
            let redirectTo

            if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.dashboard'
            if (parseInt(this.$auth.user().role) == 2) redirectTo = 'customer.earn'
            if (parseInt(this.$auth.user().role) == 3) redirectTo = 'business.dashboard'
            if (parseInt(this.$auth.user().role) == 4) redirectTo = 'business.dashboard'

            // Redirect
            this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
            this.$root.$refs.master.getCredit()
          },
          error: function (error) {
            this.loading_switch = false
            this.index_switch = -1
            this.$root.$snackbar(this.$t('not_found'))
          }
        })
        
      }
      else{
        this.form1.email = email
      }
      
    },
    AccountSession () {
      let all_account = []
      let all_user = localStorage.getItem('object_user');
      if(all_user){
        let object_user = JSON.parse(all_user)
        if(object_user && Object.keys(object_user).length != 0){
          //console.log(object_user)
          for (let key in object_user) {
            //console.log(key)
            if(key != this.$auth.user().uuid){
              let obj = object_user[key];
              //console.log(obj)
              all_account.push({
                avatar: obj.avatar,
                first_name: obj.first_name,
                last_name: obj.last_name,
                email: obj.email,
                uuid: obj.uuid,
                //token: obj.token,
                password: obj.password,
                role: obj.role,
              })
            }
          }
        }
      }
      this.all_account = all_account
    },
    RemoveAccount (uuid) {
      //console.log(uuid)
      this.$root.$confirm(this.$t('delete'), this.$t('confirm_delete'))
      .then((confirm) => {
        if (confirm.confirm) {
          let all_user = localStorage.getItem('object_user');
          if(all_user){
            let object_all_user = JSON.parse(all_user)
            if(object_all_user && Object.keys(object_all_user).length != 0){
              delete object_all_user[uuid];
              let object_user = object_all_user
              localStorage.setItem('object_user', JSON.stringify(object_user))
            }
          }
          //this.$router.go(this.$router.currentRoute)
          //this.$router.go()
          this.AccountSession()
        }
      })
    },
    async submit (formName) {
      // Get the redirect object
      let redirect = this.$auth.redirect()

      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.$auth.login({
        rememberMe: form.rememberMe,
        fetchUser: true,
        params: {
          locale: this.$i18n.locale,
          email: form.email,
          password: form.password,
          remember: form.rememberMe
        },
        success () {
          // Handle redirection
          
          if(this.$auth.check() && this.$auth.user().uuid){
            let all_user = localStorage.getItem('object_user');
            //console.log(all_user)
            if(all_user){
              let object_user = JSON.parse(all_user)
              if(object_user && Object.keys(object_user).length != 0){
                const a = 1
              }
              else{
                object_user = {}
              }

              object_user[this.$auth.user().uuid] = {
                //token: this.$auth.user().token,
                avatar: this.$auth.user().avatar,
                first_name: this.$auth.user().first_name,
                last_name: this.$auth.user().last_name,
                email: this.$auth.user().email,
                uuid: this.$auth.user().uuid,
                role: this.$auth.user().role,
              }
              if(form.rememberMe){
                //object_user[this.$auth.user().uuid]['token'] = this.$auth.user().token
                object_user[this.$auth.user().uuid]['password'] = form.password
              }
              localStorage.setItem('object_user', JSON.stringify(object_user))
            }
            else{
              let object_user = {}
              object_user[this.$auth.user().uuid] = {
                //token: this.$auth.user().token,
                avatar: this.$auth.user().avatar,
                first_name: this.$auth.user().first_name,
                last_name: this.$auth.user().last_name,
                email: this.$auth.user().email,
                uuid: this.$auth.user().uuid,
                role: this.$auth.user().role,
              }
              if(form.rememberMe){
                //object_user[this.$auth.user().uuid]['token'] = this.$auth.user().token
                object_user[this.$auth.user().uuid]['password'] = form.password
              }
              localStorage.setItem('object_user', JSON.stringify(object_user))
            }
          }
          

          let redirectTo

          if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.dashboard'
          if (parseInt(this.$auth.user().role) == 2) redirectTo = 'customer.earn'
          if (parseInt(this.$auth.user().role) == 3) redirectTo = 'business.dashboard'
          if (parseInt(this.$auth.user().role) == 4) redirectTo = 'business.dashboard'

          // Redirect
          this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
          this.$root.$refs.master.getCredit()
        },
        error: function (error) {
          window.scrollTo(0,0)
          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs[formName + '.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }

          if (errors.length === 0) {
            form.hasError = true
          }

          form.loading = false
        }
      })
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      }
    }
  }
};
</script>