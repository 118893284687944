<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs-items v-model="tab" style="background-color: transparent;">
          <v-tab-item value="tab-0">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <h1 class="fg--text display-2 font-weight-medium text--primary mb-4">{{ $t('permission') }}</h1>
              <div v-for="item in permission" :key="item.id">
                <v-switch
                  v-model="form1.select_permission[item.id]"
                  color="success"
                  flat
                  inset
                  dense
                  :ripple="false"
                  :label="$t(item.name)"
                  :disabled="item.type == 'required'" 
                ></v-switch>
              </div>
              <v-btn
                color="primary" 
                class="no-caps mt-8" rounded block x-large depressed 
                :loading="form1.loading" 
                :disabled="form1.loading" 
                @click="submit('form1')"
              >{{ $t('confirm') }} 
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: 'tab-0',
    topBgImg: 'about:blank',
    topBgImgHeight: null,
    topBgImgDefaultHeight: 780,
    topBgImgAspectRation: 1.7778,
    topBgImgs: [],
    successRegistrationRedirect: false,
    successResetUpdateRedirect: false,
    app_key: null,
    permission: [],
    form1: {
      loading: false,
      select_permission: {},
    }
  }),
  created () {
    if(!this.$auth.check()){
      this.$router.push({name: 'login-third-party', query: this.$route.query})
    }
    //console.log(this.$auth.user().role)
    if(this.$auth.user().role != 2){
      //this.$router.push({name: '404'})
      window.close()
    }
    this.successRegistrationRedirect = this.$route.params.successRegistrationRedirect || false
    this.form1.email = this.$route.params.email || null
    this.successResetUpdateRedirect = this.$route.params.successResetUpdateRedirect || false
    if(this.$route.query && this.$route.query.app_key){
      //console.log(this.$route.query.app_key)
      this.axios.post('/auth/check-app-key', {
        locale: this.$i18n.locale,
        app_key: this.$route.query.app_key,
      })
      .then(res => {
        //console.log(res.data)
        this.app_key = res.data.app_key
        this.permission = res.data.permission
        var array = {}
        for(let e of res.data.permission){
          array[e.id] = e.value
        }
        this.form1.select_permission = array
        
      })
      .catch(err => {
        window.close()
        //this.$router.push({name: '404'})
      })
    }
    else{
      window.close()
      //this.$router.push({name: '404'})
    }

  },
  methods: {
    async submit (formName) {
      // Get the redirect object
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.axios.post('/auth/login-permission-third-party', {
        app_key: this.app_key,
        state: this.$route.query.state,
        permission: JSON.stringify(this.form1.select_permission)
      })
      .then(res => {
        form.loading = false
        if (res.data.status === 'success') {
          this.FnPostMessage(res.data) 
        }
      })
      .catch(error => {
        form.loading = false
        if (error.response.data.status === 'error') {
          this.$root.$snackbar(error.response.data.error)
        }
      })
    },
    FnPostMessage (data) {
      window.opener.postMessage(JSON.stringify(data),this.$route.query.redirect_url)
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      }
    }
  }
};
</script>

<style scoped>
.justify-content-center{
  justify-content: center;
}
</style>
