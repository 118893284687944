<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab :to="{ name: 'login-third-party', query: {app_key: app_key} }" class="no-caps" :ripple="false">
            {{ $t('log_in') }}
            <!-- <v-icon size="24">mdi-account</v-icon> -->
          </v-tab>
          <v-tab :to="{ name: 'register-third-party', query: {app_key: app_key}  }" class="no-caps" :ripple="false">
            {{ $t('sign_up') }}
            <!-- <v-icon size="24">mdi-account-plus</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: transparent;" class="mt-8">
          <v-tab-item value="tab-0">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <ValidationObserver ref="form1" v-slot="{ invalid }">
                <v-form 
                  :model="form1" 
                  @submit.prevent="submit('form1')"
                  autocomplete="off"
                  method="post"
                >
                  <h1 class="fg--text display-2 font-weight-medium text--primary mb-4">{{ $t('sign_in') }}</h1>
                  <v-alert
                    :value="form1.hasError"
                    type="error"
                    tile
                    class="mb-4"
                  >
                    <span v-html="$t('login_not_recognized')"/>
                  </v-alert>
                  <v-alert
                    :value="successRegistrationRedirect"
                    type="success"
                    tile
                    class="mb-4"
                  >
                    {{ $t('successfully_registered_info') }}
                  </v-alert>
                  <v-alert
                    :value="successResetUpdateRedirect"
                    type="success"
                    tile
                    class="mb-4"
                  >
                    {{ $t('password_reset_success') }}
                  </v-alert>
                  <div v-if="$init.config.demo">
                    <div class="mb-3 font-weight-bold">
                      {{ $t('login_demo_mode') }}
                    </div>
                    <div class="mb-8">
                      <v-btn color="ctaBg ctaFg--text" width="105" small depressed tile class="mr-2 no-caps" @click="form1.email='admin@example.com';form1.password='welcome123';submit('form1')">{{ $t('admin') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn>
                      <v-btn color="ctaBg ctaFg--text" width="105" small depressed tile class="mr-2 no-caps" @click="form1.email='customer@example.com';form1.password='welcome123';submit('form1')">{{ $t('customer') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn>
                      <v-btn color="ctaBg ctaFg--text" width="105" small depressed tile class="no-caps" @click="form1.email='business@example.com';form1.password='welcome123';submit('form1')">{{ $t('business') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn>
                    </div>
                  </div>
                  <x-text-field 
                    type="email"
                    v-model="form1.email"
                    ref="form1.email"
                    id="form1.email"
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    rules="required|email"
                  />
                  <x-password
                    v-model="form1.password"
                    ref="form1.password"
                    id="form1.password"
                    :label="$t('password')"
                    :placeholder="$t('password')"
                    rules="required|min:8|max:24"
                  />
                  <x-checkbox
                    v-model="form1.rememberMe"
                    ref="form1.rememberMe"
                    id="form1.rememberMe"
                    :label="$t('remember_me')"
                  />
                  <v-btn color="primary" class="no-caps mt-4" rounded x-large depressed block :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('log_in') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: 'tab-0',
    topBgImg: 'about:blank',
    topBgImgHeight: null,
    topBgImgDefaultHeight: 780,
    topBgImgAspectRation: 1.7778,
    topBgImgs: [],
    successRegistrationRedirect: false,
    successResetUpdateRedirect: false,
    app_key: null,
    form1: {
      loading: false,
      email: '',
      password: '',
      rememberMe: true,
      hasError: false,
    }
  }),
  created () {
    //console.log(this.$auth.check())
    if(this.$auth.check()){
      this.$router.push({name: 'permission-third-party', query: this.$route.query})
    }
    this.successRegistrationRedirect = this.$route.params.successRegistrationRedirect || false
    this.form1.email = this.$route.params.email || null
    this.successResetUpdateRedirect = this.$route.params.successResetUpdateRedirect || false
    if(this.$route.query && this.$route.query.app_key){
      //console.log(this.$route.query.app_key)
      this.axios.post('/auth/check-app-key', {
        locale: this.$i18n.locale,
        app_key: this.$route.query.app_key,
      })
      .then(res => {
        //console.log(res.data)
        this.app_key = res.data.app_key
      })
      .catch(err => {
        this.$router.push({name: '404'})
      })
    }
    else{
      this.$router.push({name: '404'})
    }
    // window.addEventListener("message", (event) => {
    //   console.log(event)
    // }, false);
  },
  methods: {
    async submit (formName) {
      // Get the redirect object
      let redirect = this.$auth.redirect()

      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.$auth.login({
        rememberMe: form.rememberMe,
        fetchUser: true,
        params: {
          locale: this.$i18n.locale,
          email: form.email,
          password: form.password,
          remember: form.rememberMe
        },
        success () {
          // Handle redirection
          this.$router.push({name: 'permission-third-party', query: this.$route.query})
        },
        error: function (error) {
          window.scrollTo(0,0)
          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs[formName + '.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }

          if (errors.length === 0) {
            form.hasError = true
          }

          form.loading = false
        }
      })
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      }
    }
  }
};
</script>

<style scoped>
.justify-content-center{
  justify-content: center;
}
</style>
